// WARNING - Values under "firebase" and value of "googleApiKey" needs to be replaced from your own accounts
// If left as is, it firbase and google map related functionality will not work on LIVE instance.

export const environment = {
  production: true,
  //apiUrl: 'http://42.96.38.103/api',
  //baseUrl: 'http://42.96.38.103/',
  apiUrl: 'https://jsh-api1.vtibus.com/api',
  baseUrl: 'https://jsh-api1.vtibus.com/',
  googleClientId: '361971034113-egi4rfsbtsp409tihkuourqgldko2s3u.apps.googleusercontent.com',
  facebookClientId: '',
  googleApiKey: 'AIzaSyCQ0aGDfkCVAA-OfsKCuSa-silgehayMqM',
};
